import React from "react"
import { PageTitle, Seo } from "../components/common"
import { Layout } from "../components/layout"
import { About, Banner, Skills } from "../components/sections"

const AboutPage = () => {
  return (
    <>
      <Seo title={"About"} />

      <Layout>
        <PageTitle title="About Me" />
        <About displayTitle={false} />
        <Skills />
        <Banner />
      </Layout>
    </>
  )
}

export default AboutPage
